.toggle-switch {
  position: relative;
  width: 60px;
  height: 30px;
  background-color: #f5f5f5;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-switch.on {
  background-color: #DAE8EA;
}

.toggle-switch img {
  position: absolute;
  width: 26px;
  height: 26px;
  top: 2px;
  left: 2px;
  transition: left 0.3s ease;
}

.toggle-switch.on img {
  left: 32px;
}

.toggle-switch svg {
  position: absolute;
  width: 26px;
  height: 26px;
  top: 2px;
  left: 2px;
  transition: left 0.3s ease;
}

.toggle-switch.on svg {
  left: 32px;
}