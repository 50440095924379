/*
  Extends.scss
  Universal reusable extends
*/

%clearfix {
  &::after {
    content: ' ';
    display: table;
  }
}

%border-radius {
  border-radius: var(--border-radius);
}

%border-radius-large {
  border-radius: var(--border-radius-large);
}

%inset {
  box-shadow: inset 0 1px 2px rgba(var(--black-rgb), .12);
}

%distance-none {
  box-shadow: none;
}

%distance-small {
  box-shadow: 0 1px 1px rgba(var(--black-rgb), .10),
  0 1px 3px rgba(var(--black-rgb), .10);
}

%distance-medium {
  box-shadow: 0 1px 2px rgba(var(--black-rgb), .10),
  0 1px 4px rgba(var(--black-rgb), .10),
  0 2px 8px rgba(var(--black-rgb), .10);
}

%distance-large {
  box-shadow: 0 1px 2px rgba(var(--black-rgb), .05),
  0 1px 4px rgba(var(--black-rgb), .10),
  0 2px 8px rgba(var(--black-rgb), .10),
  0 4px 16px rgba(var(--black-rgb), .10);
}

%distance-larger {
  box-shadow: 0 1px 2px rgba(var(--black-rgb), .05),
  0 1px 4px rgba(var(--black-rgb), .10),
  0 2px 8px rgba(var(--black-rgb), .15),
  0 4px 16px rgba(var(--black-rgb), .15),
  0 8px 32px rgba(var(--black-rgb), .10);
}

%distance-huge {
  box-shadow: 0 1px 2px rgba(var(--black-rgb), .05),
  0 1px 4px rgba(var(--black-rgb), .10),
  0 2px 8px rgba(var(--black-rgb), .15),
  0 4px 16px rgba(var(--black-rgb), .25),
  0 8px 32px rgba(var(--black-rgb), .65);
}

%dot {
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: x(.5);
    right: x(.5);
    width: 5px;
    height: 5px;
    background: var(--fg-assist);
    border-radius: 50%;
  }
}

%has-content {
  @extend %dot;
}

%is-live {
  @extend %dot;

  &::after {
    @extend %animation-pulsate;

    background-color: var(--green);
  }
}

// Focus

%focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--bdr-assist);
  position: relative;
}

%focus-inline {
  @extend %focus;

  box-shadow: inset 0 0 0 2px var(--bdr-assist);
}

// "Focusable"
// ...an element that can be focused

%focusable {
  cursor: pointer;

  &:focus {
    @extend %focus-inline;
  }
}

%avatar-outline {
  display: inline-block;
  position: relative;

  img {
    display: block;
    border-radius: 100%;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    z-index: 0;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    border: 1px solid var(--bdr-black);
    border-radius: 100%;
  }
}

%avatar-outline-active {
  &::before {
    border-width: 2px;
  }
}

// Field
// Base form of a form field

%field {
  @extend %distance-small;
  @extend %animate-distance;
  @extend %border-radius;

  box-sizing: border-box;
  min-height: var(--touch-target);
  line-height: var(--line-height);
  padding: 0 x(1);
  background: var(--fld-bg);
  color: var(--fld-fg);
  border: 1px solid var(--fld-bdr);
}

%field-hover {
  @extend %distance-medium;

  background-color: var(--fld-bg-hover);
  color: var(--fld-fg-hover);
  border-color: var(--fld-bdr-hover);
}

%field-focus {
  @extend %focus;
}

%field-disabled {
  box-shadow: none;
  cursor: not-allowed;
  background-color: var(--fld-bg-disabled);
  color: var(--fld-fg-disabled);
  border-color: var(--fld-bdr-disabled);
}

%field-error {
  border-color: var(--fld-bdr-error);
}

%field-warning {
  border-color: var(--fld-bdr-warning);
}

%field-success {
  border-color: var(--fld-bdr-success);
}

%link {
  @extend %animate-colors;

  color: var(--fg-link);
  cursor: pointer;

  &:hover {
    color: var(--fg-link-hover);
  }

  &:active {
    color: var(--fg-link-pressed);
  }

  &:focus {
    outline: none;
  }

  &.is-disabled {
    color: var(--fg-link-disabled);
  }
}

// Nav button
// hover/focus/active effects for nav items

%nav-button {
  @extend %animate-colors;
  @extend %focusable;

  flex: 1 1 auto;
  flex-direction: row;
  align-items: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 0 x(1);
  min-width: var(--touch-target);
  text-align: center;

  &:not(.is-active) & {
    cursor: pointer;
  }

  &:not(.is-active):hover,
  &:not(.is-active):focus {
    background-color: var(--bg-nav-hover);
  }

  &:not(.is-active):active {
    background-color: var(--bg-nav-pressed);
  }

  &.is-active {
    background: var(--bg-nav-selected);
    opacity: 1;
  }

  &.is-active:not(.navbar__item--notifications):not(.navbar__item--search):not(.toolbar__item--filter) {
    cursor: default;
  }

  &.has-content {
    @extend %has-content;
  }

  &::before {
    content: '';
    height: 1px;
    position: absolute;
    z-index: 1;
    left: 50%;
    right: 50%;
    bottom: -1px;
    background: var(--accent-dark);
    opacity: 0;
    transition: opacity 100ms ease,
    left 200ms ease-out,
    right 200ms ease-out;
  }

  &.is-active::before,
  &:hover::before,
  &:focus::before {
    opacity: 1;
    left: 0;
    right: 0;
  }

  &.is-active::before {
    background-color: var(--accent);
  }
}

// "Actionable"
// ...an interactive element with an action

%actionable {
  @extend %animate-colors;
  @extend %focusable;

  cursor: pointer;

  &:hover,
  &:focus {
    background-color: var(--bg-nav-selected);
  }

  &:active {
    background-color: var(--bg-nav-pressed);
  }
}

// "Draggable"
// ..an element that can be dragged

%draggable {
  cursor: move;
  cursor: grab;
}

%dragging {
  @extend %focusable;
  @extend %distance-large;

  position: absolute;
  z-index: 100;
  cursor: grabbing;
}

// Button

%button {
  @extend %font-size;
  @extend %distance-small;
  @extend %border-radius;
  @extend %animate-distance;
  @extend %animate-colors;

  padding: 0 x(2);
  min-height: var(--touch-target);
  min-width: var(--touch-target);
  background-color: var(--btn-secondary);
  color: var(--btn-secondary-fg);
  text-align: center;
  border: 1px solid var(--btn-secondary-bdr);
  cursor: pointer;
  transition: background-color .2s ease,
  color .2s ease,
  box-shadow ease-out .2s;

  &:not(.is-destructive) {
    border: 1px solid var(--btn-secondary-bdr);
  }

  .has-action:hover .btn-toolbar:not(:hover) &.is-primary-action:not(.is-tertiary),
  &:hover {
    @extend %distance-medium;

    z-index: 1;
  }

  &:active {
    @extend %distance-none;
  }

  .has-action:focus .btn-toolbar:not(:hover) &.is-primary-action:not(.is-tertiary),
  &.is-selected,
  &:focus {
    @extend %focus;

    z-index: 1;
  }

  &:disabled {
    box-shadow: none;
    cursor: not-allowed;
  }

  &.is-error {
    border-color: var(--bdr-error);
  }

  &.is-warning {
    border-color: var(--bdr-warning);
  }

  &.is-success {
    border-color: var(--bdr-success);
  }

  > span {
    vertical-align: middle;
  }

  .count {
    @extend %count;

    margin-right: calc(#{x(.5)} + 2px);
  }
}

%button-small {
  min-height: var(--icon-2);
  min-width: var(--icon-2);

  &:not(.button--no_label) {
    padding: 0 x(1);
  }

  &:not(.button--no_label) span.icon {
    margin: 0 x(1) 0 x(-1);
  }

  .btn-toolbar &:last-child {
    margin-right: 0;
  }
}

%button-tiny {
  min-height: var(--icon);
  min-width: var(--icon);

  &:not(.button--no_label) {
    padding: 0 x(.5);
  }

  &:not(.button--no_label) span.icon {
    margin: 0 x(.5) 0 x(-.5);
  }

  .btn-toolbar &:last-child {
    margin-right: 0;
  }
}

%button-primary {
  background-color: var(--btn-primary);
  color: var(--btn-primary-fg);
  border-color: var(--btn-primary-bdr);

  .has-action:hover .btn-toolbar:not(:hover) &.is-primary-action:not(.is-tertiary),
  .has-action:focus .btn-toolbar:not(:hover) &.is-primary-action:not(.is-tertiary),
  &:focus,
  &:hover {
    background-color: var(--btn-primary-hover);
  }

  &:active {
    background-color: var(--btn-primary-pressed);
    color: var(--btn-primary-fg-pressed);
  }

  &:disabled {
    background-color: var(--btn-primary-disabled);
    color: var(--btn-primary-fg-disabled);
  }

  span.icon:not(.is-color) *[stroke] {
    stroke: var(--white);
  }

  span.icon:not(.is-color) *[fill]:not([fill="none"]) {
    fill: var(--white);
  }
}

%button-secondary {
  background-color: var(--btn-secondary);
  color: var(--btn-secondary-fg);

  &:not(.is-destructive):not(.is-tertiary):not(:disabled) {
    border: 1px solid var(--btn-secondary-bdr);
  }

  .has-action:hover .btn-toolbar:not(:hover) &.is-primary-action:not(.is-tertiary),
  .has-action:focus .btn-toolbar:not(:hover) &.is-primary-action:not(.is-tertiary),
  &:focus,
  &:hover {
    background-color: var(--btn-secondary-hover);
  }

  &:active {
    background-color: var(--btn-secondary-pressed);
    color: var(--btn-secondary-fg-pressed);
  }

  .is-disabled,
  &:disabled {
    background-color: var(--btn-secondary-disabled);
    color: var(--btn-secondary-fg-disabled);
    border-color: var(--btn-secondary-bdr-disabled);

    span.icon {
      opacity: .65;
    }
  }
}

%button-destructive {
  background-color: var(--btn-destructive);
  color: var(--btn-destructive-fg);

  /// too much with tertiary here?
  .has-action:hover .btn-toolbar:not(:hover) &.is-primary-action:not(.is-tertiary),
  .has-action:focus .btn-toolbar:not(:hover) &.is-primary-action:not(.is-tertiary),
  &:focus,
  &:hover {
    background-color: var(--btn-destructive-hover);
  }

  &:active {
    background-color: var(--btn-destructive-pressed);
    color: var(--btn-destructive-fg-pressed);
  }

  &:disabled {
    background-color: var(--btn-destructive-disabled);
    color: var(--btn-destructive-fg-disabled);
  }
}

%link-destructive {
  color: var(--btn-tertiary-fg-destructive);

  svg.icon:not(.is-color) *[stroke] {
    @extend %animate-colors;

    stroke: var(--btn-tertiary-fg-destructive);
  }

  svg.icon:not(.is-color) *[fill]:not([fill="none"]) {
    @extend %animate-colors;

    fill: var(--btn-tertiary-fg-destructive);
  }

  svg.icon__edit circle {
    @extend %animate-colors;

    fill: var(--btn-tertiary-fg-destructive);
  }

  &:focus,
  &:hover {
    color: var(--btn-tertiary-fg-destructive-hover);

    svg.icon:not(.is-color) *[stroke] {
      stroke: var(--btn-tertiary-fg-destructive-hover);
    }

    svg.icon:not(.is-color) *[fill]:not([fill="none"]) {
      fill: var(--btn-tertiary-fg-destructive-hover);
    }

    svg.icon__edit circle {
      fill: var(--btn-tertiary-fg-destructive-hover);
    }
  }

  &:active {
    color: var(--btn-tertiary-fg-destructive-pressed);

    svg.icon:not(.is-color) *[stroke] {
      stroke: var(--btn-tertiary-fg-destructive-pressed);
    }

    svg.icon:not(.is-color) *[fill]:not([fill="none"]) {
      fill: var(--btn-tertiary-fg-destructive-pressed);
    }

    svg.icon__edit circle {
      fill: var(--btn-tertiary-fg-destructive-presed);
    }
  }

  &.is-disabled {
    color: var(--btn-tertiary-fg-destructive-disabled);

    svg.icon:not(.is-color) *[stroke] {
      stroke: var(--btn-tertiary-fg-destructive-disabled);
    }

    svg.icon:not(.is-color) *[fill]:not([fill="none"]) {
      fill: var(--btn-tertiary-fg-destructive-disabled);
    }

    svg.icon__edit circle {
      fill: var(--btn-tertiary-fg-destructive-disabled);
    }
  }
}

// Inner width

%inner {
  max-width: var(--width-inner);
  padding: 0 x(4);
  margin: 0 auto;
  box-sizing: border-box;
}

// Unselectable

%unselectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// Card

%card {
  @extend %distance-small;
  @extend %border-radius-large;
  @extend %animate-distance;

  background: var(--bg-card);
  color: var(--fg);
  position: relative;
  margin: 0 auto;
  padding: x(3);
  box-sizing: border-box;
}

%card-large {
  @extend %card;

  padding: x(4);
}

%card-small {
  @extend %card;

  padding: x(2);
}

%card-tiny {
  @extend %card;

  padding: x(.5);
}

%card-inverted {
  box-shadow: none;
  background: var(--bg);
}

// Scroll chrome fix
// Needed to fix Chrome bug with repainting scrollable containers
/// See if needed (last check 2020-04-22)

%chrome-scroll-fix {
  transform: translate3d(0, 0, 0);
}

// Scroll columns

%column-scroll {
  overflow: auto;
  overflow-x: hidden;
  overflow-y: scroll;
}

%column-scroll-overlay {
  @extend %column-scroll;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding-bottom: x(8);
}

// Dropzone

%dropzone-is-ready {
  background-color: rgba(var(--green-rgb), .2);
  border: 1px dashed var(--green);
}

%dropzone-is-error {
  background-color: rgba(var(--red-rgb), .2);
  border: 1px dashed var(--red);

  .description {
    font-weight: bold;
  }
}

%dropzone-area {
  @extend %border-radius-large;
  @extend %animate-colors;
  @extend %focusable;

  background-color: var(--bg-light);
  border: 1px dashed var(--bdr);
  color: var(--fg-light);
  padding: 0 x(1);

  input[type=file] {
    position: relative;
    right: -64px;
    margin: 0 auto;
  }

  .btn-toolbar {
    flex-wrap: nowrap;
  }

  .btn-toolbar .form-group,
  .btn-toolbar > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  .btn-toolbar .form-group > * {
    margin-bottom: 0;
  }

  .btn-toolbar > .button:first-of-type {
    flex: 1 0 auto;
    white-space: nowrap;
    margin-top: -1px;
    margin-left: calc(#{x(-1)} - 1px);
    margin-bottom: -1px;
  }

  &.is-active {
    @extend %dropzone-is-ready;
  }

  &.is-error {
    @extend %dropzone-is-error;
  }

  .btn-toolbar .form-group {
    flex: 2 0 auto;
  }

  .btn-toolbar .form-group--static {
    @extend %font-size-smaller;

    color: var(--fg-light);
    flex: 0 0 auto;

    &--or {
      font-style: italic;
    }
  }
}

// Inline count

%count {
  @extend %font-size-small;

  display: inline-block;
  min-width: calc(var(--icon) + 2px);
  height: calc(var(--icon) + 2px);
  line-height: calc(var(--icon) - 4px);
  box-sizing: border-box;
  text-align: center;
  margin-right: x(.5);
  padding: 2px x(.5);
  border-radius: x(1.5);
  background: var(--bg-count);
  color: var(--fg-count);
  border: 1px solid var(--bdr-count);
}

%count-small {
  @extend %count;
  @extend %font-size-smaller;

  line-height: calc(var(--icon) - 3px);
  min-width: var(--icon);
  height: var(--icon);
  padding: 0 x(.5);
}

%count-large {
  @extend %count;

  min-width: calc(var(--icon) + 6px);
  height: calc(var(--icon) + 6px);
  line-height: var(--icon);
}

%count-new {
  @extend %count;

  border-radius: x(1);
  min-width: var(--icon);
  background: var(--bg-count-new);
  color: var(--fg-count-new);
  border: 1px solid var(--bdr-count-new);
}

%count-small-new {
  @extend %count-small;

  border-radius: x(1);
  min-width: var(--icon);
  background: var(--bg-count-new);
  color: var(--fg-count-new);
  border: 1px solid var(--bdr-count-new);
}
