/*
  Mixins.scss
  Time-saving global mixins
*/

// Get distance based on grid

@function x($size: 1) {
  @if ($size == 1) {
    @return var(--x);
  } @else {
    @return calc(var(--x) * #{$size});
  }
}

/// Convert hex to RGB (not used afaik)

@function hexrgb($hex) {
  @return red($hex), green($hex), blue($hex);
}

// Gradient

@mixin gradient($from, $to) {
  background-color: $from;
  background-image: -moz-linear-gradient($from, $to);
  background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from($from), to($to));
  background-image: -webkit-linear-gradient($from, $to);
  background-image: -o-linear-gradient($from, $to);
}

// Label color

@mixin label($color) {

  &::before {
    background: $color;
  }
}

// Toggle in object head

@mixin toggle($elementName: toggle) {
  flex: 0 0 22px;
  border: 1px solid transparent; // Add border first
  border-radius: 50% 50%;
  padding: 0;

  &:hover,
  &:focus,
  .#{$elementName}:hover &,
  .#{$elementName}:focus & {
    @extend %button;
    @extend %distance-medium;

    border-radius: 50% 50%;
    padding: 0;
    min-width: 0;
    min-height: 0;

    svg.icon:not(.is-filled) *,
    svg.icon:not(.is-filled) * {
      stroke: var(--fg-link);
    }
  }

  div:not(.#{$elementName}--expanded) & svg.icon:not(.is-filled) * {
    stroke: var(--fg-lighter);
  }

  .#{$elementName}--expanded & svg.icon:not(.is-filled) * {
    stroke: var(--fg-link);
  }

  svg.icon {
    transition: transform .2s ease;
    transform-origin: 50% 50%;

    .#{$elementName}--expanded & {
      transform: rotate(-180deg);
    }
  }
}

// Toggle in object head

@mixin toggleAll($parent: fnxtable) {
  @extend %button;

  flex: 0 0 22px;
  border-radius: 50% 50%;
  padding: 0;
  min-width: 0;
  min-height: 0;

  // Default button override
  .app & {
    background: none;
    border: 1px solid transparent; // Add border first
    box-shadow: none;
  }

  .app thead:hover &,
  .app &:hover,
  .app &:focus {
    @extend %button;


    border-radius: 50% 50%;
    padding: 0;
    min-width: 0;
    min-height: 0;

    svg.icon:not(.is-filled) *,
    svg.icon:not(.is-filled) * {
      stroke: var(--fg);
    }
  }

  .app & svg.icon:not(.is-filled) *,
  .app & svg.icon:not(.is-filled) * {
    stroke: var(--fg-lighter);
  }

  div.app &:hover,
  div.app &:focus {
    @extend %distance-medium;

    svg.icon:not(.is-filled) *,
    svg.icon:not(.is-filled) * {
      stroke: var(--fg-link);
    }
  }

  svg.icon__expand polyline {
    transition: transform .2s ease;
    transform-origin: 50% 50%;
  }

  .#{$parent}--expanded & svg.icon__expand polyline:first-child {
    transform: translateY(-6px);
  }

  .#{$parent}--expanded & svg.icon__expand polyline:last-child {
    transform: translateY(6px);
  }
}

// List

%list {

  &:not(:last-child) {
    margin-bottom: x(2);
  }
}

@mixin list__item($elementName: list__item) {
  cursor: default;
  margin: 0 x(-2);

  &:not(:last-child):not(&--expanded) {
    border-bottom: 1px solid var(--bdr-light);
  }

  &:not(:first-child):not(&--expanded) {
    border-top: 1px solid var(--bdr-light);
    margin-top: -1px;
  }

  .content {
    display: flex;
    flex-wrap: nowrap;
  }

  &--expanded {
    flex-wrap: wrap;
    background-color: var(--bg-light);
    flex-wrap: wrap;
    margin-top: x(.5);
    margin-bottom: x(.5);
  }

  // Highlight if new or updated
  &.is-new,
  &.has-updated {
    @extend %animation-new;
  }

  // List item head

  .#{$elementName}__head {
    @extend %focusable;

    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: x(.5) x(2);
    cursor: default;

    // Highlight primary action

    &.has-action:hover {
      @extend %focusable;

      .btn-toolbar {
        cursor: default;
      }
    }
  }

  &:not(.#{$elementName}--edit):not(.#{$elementName}--add) .#{$elementName}__head {
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: var(--turqoise-lightest);
    }
  }

  // List item content

  .#{$elementName}__content {
    padding: x(1.5) x(2);
    margin-left: x(5);

    #app & .form-group--columns {
      display: flex;
      flex-wrap: nowrap;
    }

    #app & .form-group--columns .form-group__column {
      margin-top: 0;
      margin-bottom: 0;
      width: auto;
      flex: 1 1 auto;
    }
  }

  // Description

  .#{$elementName}__description {
    flex: 1 1 auto;
    display: flex;
    margin: x(.5) 0;
    align-items: center;

    .title {
      margin: 0;
      flex: 1 1 auto;

      .tag {
        margin-top: -2px;
      }
    }
  }

  // Add toolbar

  .#{$elementName}__add_toolbar {
    flex: 1 1 auto;

    // Align with text in normal rows
    .text--max_width:first-child {
      margin-left: x(-1);
    }
  }

  // List item foot

  .#{$elementName}__foot {
    padding: x(1.5) x(2) 0;
    flex: 1 1 100%;
    border-top: 1px solid var(--bdr-light);

    .btn-toolbar .right {
      margin-right: 0;

      > * {
        margin-right: 0;
      }
    }
  }
}
