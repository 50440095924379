/*
  app.scss
  Fundamentals of the app
*/

// Defaults

html,
body,
#root,
.app {
  height: 100%;
  background: var(--bg);
}

body {
  @extend %font-family;

  color: var(--fg);
  min-width: var(--width-min);
  margin-bottom: 0; // needed
}

a {
  @extend %link;
}

// Test

.test {
  outline: 1px solid #f6c !important;
}

// Selection

::selection {
  background: var(--accent);
}

::-moz-selection {
  background: var(--accent);
}

// Scrollbars

::-webkit-scrollbar {
  width: x(1.5);
  height: x(1.5);
  background: var(--bg-scrollbar);

  &:hover {
    background-color: var(--bg-scrollbar-hover);
  }
}

::-webkit-scrollbar-track {
  background: var(--bg-scrollbar);

  &:hover {
    background-color: var(--bg-scrollbar-hover);
  }
}

::-webkit-scrollbar-thumb {
  border-radius: calc(#{x(1.5)} / 2);
  background-color: transparent;
  box-shadow: inset 0 0 0 2px transparent,
  inset 0 0 0 4px var(--bg-scrollbar-thumb);
  border: 2px solid transparent;

  &:hover {
    box-shadow: inset 0 0 0 2px transparent,
    inset 0 0 0 4px var(--bg-scrollbar-thumb-hover);
  }

  &:active {
    box-shadow: inset 0 0 0 2px transparent,
    inset 0 0 0 4px var(--bg-scrollbar-thumb-active);
  }
}

// Icon
// Set default colors for icons

.icon {

  svg:not(.is-color):not(.is-static) *[stroke] {
    stroke: var(--icn-stroke);
  }

  svg:not(.is-color):not(.is-static) *[fill]:not([fill="none"]) {
    fill: var(--icn-fill);
  }

  svg.is-color:not(.is-static):not([class*=" icon__order"]):not([class*=" icon__group"]):not([class*=" icon__process"]):not([class*=" icon__document"]):not([class*=" icon__map_legend"]) *[fill]:not([fill="none"]) {
    fill: var(--icn-fill);
  }
}

// Misc

.align_right {
  float: right;
}

.align_left {
  float: left;
}

.destructive {
  color: var(--fg-light);

  path {
    @extend %animate-colors;

    stroke: var(--fg-light);
  }

  &:hover,
  &:focus {
    color: var(--red);

    path {
      stroke: var(--red);
    }
  }

  &:active {
    color: var(--red-dark);

    path {
      stroke: var(--red);
    }
  }
}

.is-placeholder {
  color: var(--fg-lighter);
}

.no_data {
  color: var(--fg-lighter);
}

// Loader

.load {
  @extend %animate-opacity;

  pointer-events: none;
  text-align: center;
  position: absolute;

  &.show-loader {
    opacity: 1;
  }

  &.hide-loader {
    opacity: 0;
  }

  &--cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    background: transparent;
    overflow: hidden;
  }

  .column__section__head &--tiny {
    margin-right: x(.5);
  }

  &--item {
    padding: x(2) 0;
  }
}

.load__inner {
  text-align: center;
  width: 100%;

  > div {
    display: inline;
  }

  svg {
    stroke: var(--blue) !important;

    g {
      stroke-width: 2px !important;
    }
  }

  .load--tiny & svg g {
    stroke-width: 4px !important;
  }
}

// message empty white
.message--empty--case {
  height: 100%;
  background-color: var(--bg);
  display: flex;
  align-items: center;
  flex-direction: row;
  text-align: center;
}

// image maintaining ratio

.image_ratio {
  object-fit: contain;
}

// App

.app {
  //display: flex;
  //flex-direction: row;

  &:empty {
    display: none;
  }
}

// Navbar

.navbar {
  display: block;
  border-radius: 0;
}

.navbar > .container-fluid {
  display: flex;
  flex-direction: row;
  height: var(--height-panel_head);
  margin: 0;
  padding: 0;
  margin: 0 -1px;
}

// Navbar section
// Divided by vertical bars

.navbar__section {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;

  &--spacer {
    flex: 1 1 16px;
    min-width: x(2);
  }
}

// Navbar item

.navbar__section .navbar__item {
  @extend %nav-button;

  min-width: 72px;
  height: var(--height-panel_head);
}

// Navbar item icon

.navbar__item .avatar,
.navbar__item span.icon {
  margin: x(.5) auto 0;
  width: var(--icon-2);
  height: var(--icon-2);
  display: block;
}

.navbar__item .avatar {
  @extend %avatar-outline;

  margin-top: 6px;
  margin-bottom: 2px;

  &,
  > img {
    width: calc(var(--icon-2) - 2px);
    height: calc(var(--icon-2) - 2px);
  }
}

// Navbar item description

.navbar__item__description {
  @extend %font-size-smaller;

  text-align: center;
  color: var(--fg);
  margin-top: calc(#{x(.5)} + 1px);
  display: block;
  white-space: nowrap;
}

// Navbar item count

.navbar__item__count {
  display: flex;

  .count {
    @extend %count-small-new;

    position: absolute;
    top: x(.5);
    right: 0;
  }

  .navbar__item.wiggle & {
    svg > g {
      animation: wiggle 8s 4s linear infinite;
      transform-origin: 50% 0;

      path:last-child {
        animation: wiggle-pendulum 8s 4s linear infinite;
      }
    }
  }
}

// Panel

.panel {
  // Reset
  box-shadow: none;
  background: none;
  margin: 0;
  border-radius: 0;
  border: none;
  height: 100%;
  position: relative;

  &--left {
    flex: 0 0 var(--width-panel);
    z-index: 11;
    border-right: 1px solid var(--bdr);
  }

  &--workarea {
    flex: 1 1 auto;
  }
}

.panel__head {
  @extend %animate-colors;

  box-sizing: content-box;
  height: var(--height-panel_head);
  position: fixed;
  right: 0;
  left: 0;
  z-index: 2;
  margin-bottom: 0;
  transition: all .2s ease;
  background: var(--bg-system);
  border: none;
  border-bottom: 1px solid var(--bdr);

  // Global loading indicator
  &::after {
    @extend %animate-opacity;

    content: "";
    display: block;
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: -1px;
    left: 0;
    height: 1px;
    opacity: 0;
    background: var(--fg-assist);
  }

  .app.is-loading & {
    @extend %animation-loading;
  }
}

// Panel content

.panel__content {
  padding-top: x(6);
  padding-bottom: x(8);

  &--main {
    padding: 0;
    position: absolute;
    top: var(--height-panel_head);
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: flex;
    border-top: 1px solid var(--bdr);

    .columns {
      position: relative;
      top: 0;
      border-top: none;
      flex: 1 1 auto;
    }
  }
}

.panel__toolbar.btn-toolbar {
  margin-bottom: x(6);
}

// Panel section

.column__section {
  border-bottom: 1px solid var(--bdr-light);
}

.column__section__head {
  @extend %animate-colors;
  @extend %font-size-small;

  padding: 0 x(2);
  height: var(--icon-1-5);
  background-color: var(--bg-light);
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--fg-light);

  span.icon:not(.is-color) *[stroke] {
    stroke: var(--fg-light);
  }

  // Hover
  &:focus,
  &:hover {
    background-color: var(--bg);
    color: var(--fg-dark);

    span.icon:not(.is-color) *[stroke] {
      stroke: var(--fg-dark);
    }
  }

  // Active
  .column__section.is-active & {
    background-color: var(--bg-white);
  }

  .description {
    flex: 1 1 auto;
  }

  .count {
    @extend %count-small;

    span.icon {
      vertical-align: bottom;
    }
  }

  span.icon {
    order: 2;
  }

  svg.icon__chevron_up {
    transition: transform .3s ease;
    margin: 0;

    .column__section:not(.is-active) & {
      transform: rotate(-180deg);
      transform-origin: 50%;
    }
  }

  .column__section.is-loading & {
    @extend %animation-pulsate;
  }
}

.column__section:not(.is-active) .column__section__content {
  display: none;
}

main {
  position: absolute;
  z-index: 2;
  top: var(--height-panel_head);
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 1px solid var(--bdr);
}

// Columns

.columns {
  display: flex;
  flex-direction: row;
  margin-right: 1px; /// Hides last column border out of sight on load

  &--static {
    @extend %chrome-scroll-fix;

    display: block;
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &--help {
    display: flex;
    flex-direction: column;
  }

  // Settings for when an element is being dragged
  &--have_drag {

    .drag__content__list {
      overflow-y: hidden;
    }

    .drag__content__list .draggable.is-still {
      opacity: .5;
      transform: none !important;
    }

    .drag__content__list > div > .item--no_content {
      display: none !important;
    }
  }

  hr {
    border-color: var(--bdr);
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

// Inner

.inner {
  @extend %inner;

  .drawer & {
    padding: 0;
  }

  .inner + & {
    margin-top: x(6);
  }
}

//eventTypeCheckbox for Admincategory
/// what is this??
.mar {
  margin-top: -130px;
  margin-left: 520px;
}

// Relative Delete Buttom
// misspelled
.crossButtom {
  font-size: large;
  float: right;
}

.searchLoader {
  display: inline-block;
  padding-left: 185px;
}
