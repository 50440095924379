/*
  typography.scss
  Typography defaults and extends
*/

// Import Raleway from Google Fonts

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600&display=swap');

:root {

  // Text scale
  --font-size-huge: 2.441rem;
  --font-size-largest: 1.953rem;
  --font-size-larger: 1.563rem;
  --font-size-large: 1.25rem;
  --font-size: 1rem; // .875em, 14px
  --font-size-small: 0.9rem;
  --font-size-smaller: 0.8rem;
  --font-size-smallest: 0.7rem;

  // Line height
  --line-height: 1.44em;

}

strong, b {
  font-weight: bold;
  font-weight: 600;
}

// Extends

%letter-spacing {
  letter-spacing: .06em;
}

%font-family {
  @extend %letter-spacing;

  font-family: 'Raleway', 'Tahoma', 'Lucida Grande', sans-serif;
  font-weight: 500;

  // Use lining numerals
  -webkit-font-feature-settings: "lnum";
  -moz-font-feature-settings: "lnum";
  font-feature-settings: "lnum";
}

%font-family-loaded {
  @extend %font-family; // for now
}

%font-size-huge {
  @extend %letter-spacing;

  font-size: var(--font-size-huge);
}

%font-size-largest {
  @extend %letter-spacing;

  font-size: var(--font-size-largest);
}

%font-size-larger {
  @extend %letter-spacing;

  font-size: var(--font-size-larger);
}

%font-size-large {
  @extend %letter-spacing;

  font-size: var(--font-size-large);
}

%font-size {
  @extend %letter-spacing;

  font-size: var(--font-size);
}

%font-size-small {
  @extend %letter-spacing;

  font-size: var(--font-size-small);
}

%font-size-smaller {
  @extend %letter-spacing;

  font-size: var(--font-size-smaller);
}

%font-size-smallest {
  @extend %letter-spacing;

  font-size: var(--font-size-smallest);
}

// Defaults

html {
  font-size: .875em;
}

body {
  @extend %font-family;

  margin-bottom: x(2);

  &.font_loaded {
    @extend %font-family-loaded;
  }
}

code {
  font-family: monospace;
}

p {
  @extend %font-size;

  line-height: var(--line-height);
  margin-bottom: x(2);
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  font-weight: 400;
  margin-bottom: x(2);

  span.icon {
    margin-right: x(1);
  }
}

h4, h5, h6 {
  font-weight: bold;
  font-weight: 600;

  span.icon {
    margin-right: x(.5);
  }
}

small {
  font-size: var(--font-size-small);
  line-height: var(--line-height);
}

h5 {
  line-height: var(--line-height);
}

h4 {
  font-size: var(--font-size-large);
  line-height: var(--line-height);
}

h3 {
  font-size: var(--font-size-larger);
  line-height: var(--line-height);
}

h2 {
  font-size: var(--font-size-largest);
  line-height: var(--line-height);
}

h1 {
  font-size: var(--font-size-huge);
  line-height: var(--line-height);
}
