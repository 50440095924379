/*
  Forms
  Label, select, input, textarea, static_field, help_text
*/

// Label

label {
  display: block;
  padding-bottom: x(1.5);
  line-height: var(--line-height);
  font-weight: 500;

  // Fix to make the inline textarea field label background disabled
  #app.app .field--textarea &.disabled:first-child {
    background: var(--fld-bg-disabled);
  }
}

// Form fields

input, select, textarea {
  @extend %font-family;

  line-height: var(--line-height);
  margin-bottom: x(1.5);
}

.text,
.textarea,
select,
.multiselect .multiselect__control {
  @extend %field;
}

// Hover/focus

.text,
select,
.textarea,
.multiselect .multiselect__control {
  &:hover,
  &:focus {
    @extend %field-hover;
  }
}

// Focus

.text:focus,
.textarea:focus,
select:focus,
.multiselect .multiselect__control--is-focused,
.multiselect .multiselect__control--is-focused:hover {
  @extend %field-focus;
}

// Disabled

.text:disabled,
.textarea:disabled,
select:disabled,
.multiselect .multiselect__control--is-disabled {
  @extend %field-disabled;
}

// Statuses

// Error
.text.is-error,
select.is-error,
.textarea.is-error,
.multiselect.is-error .multiselect__control {
  @extend %field-error;
}

// Warning
.text.is-warning,
select.is-warning,
.textarea.is-warning,
.multiselect.is-warning .multiselect__control {
  @extend %field-warning;
}

// Success
.text.is-success,
select.is-success,
.textarea.is-success,
.multiselect.is-success .multiselect__control {
  @extend %field-success;
}

// Checkbox, radio

input[type=checkbox],
input[type=radio] {
  position: absolute;
  opacity: 0;

  // Label
  + .option__description {
    padding-left: 0;
    padding: 0;
    cursor: pointer;

    // Option
    &::before {
      @extend %distance-small;
      @extend %border-radius;
      @extend %animate-distance;

      transition: box-shadow ease-out .2s,
      background-color .2s ease,
      border-color .2s ease;
      content: '';
      background: var(--opt-bg);
      border: 1px solid var(--opt-bdr);
      display: inline-block;
      width: 18px;
      height: 18px;
      position: relative;
      top: 0;
      margin-right: x(1);
      vertical-align: middle;
      cursor: pointer;
      text-align: center;
      background-size: 24px;
      background-position: 50%;
    }

    // Option Radio
    &--radio::before {
      border-radius: 100%;
    }

    // Empty description
    &:empty::before {
      margin-right: 0;
    }
  }

  // Hover
  &:hover + .option__description::before,
  &:focus + .option__description::before {
    @extend %distance-medium;

    background-color: var(--opt-bg-hover);
    border-color: var(--opt-bdr-hover);
  }

  // Checked
  &:checked + .option__description::before {
    background-color: var(--opt-bg-checked);
    ///box-shadow: inset 0 0 0 2px var(--bg-white);

    path {
      stroke: var(--opt-fg-checked);
    }
  }

  &:checked + .option__description--radio::before {
    box-shadow: inset 0 0 0 2px var(--opt-bg);
  }

  &:checked + .option__description--checkbox::before {
    border-color: var(--opt-bdr-checked);
    box-shadow: none;
    background-image: var(--url-svg-check);
  }

  // Focused
  body &:focus + .option__description--checkbox::before {
    @extend %focus;
  }

  body &:focus + .option__description--radio::before {
    box-shadow: inset 0 0 0 2px var(--opt-bg),
    0 0 0 2px var(--fg-assist);
  }

  // Disabled
  &:disabled + .option__description {
    cursor: default;
  }

  &:disabled + .option__description::before {
    box-shadow: inset 0 0 0 2px var(--bg-white);
    border-color: var(--opt-bdr-disabled);
    background-color: var(--opt-bg-disabled);
    cursor: default;
  }

  &:disabled:checked + .option__description::before {
    border-color: var(--opt-bdr-checked-disabled);
    background-color: var(--opt-bg-checked-disabled);
  }

  &:disabled + .option__description--checkbox::before {
    box-shadow: none;
  }
}

// Option (Checkbox, radio)

.option {

  &--inline {
    display: inline;

    &:not(:last-child) {
      margin-right: x(2);
    }

    label {
      display: inline;
    }
  }

  &:not(.option--confirm_field):not(.option--no_option) {
    margin-top: x(1.5);
    margin-bottom: x(1.5);
  }

  &--confirm_field {
    @extend %border-radius;

    background: var(--bg-light);
    box-shadow: inset 0 0 0 1px var(--opt-bdr);
    margin-top: 0;
    display: flex;

    select, input, button, .option, .multiselect {
      margin-bottom: 0;
    }

    // Confirm child option (pall bearers)
    .app & .option {
      border-left: 1px solid var(--fld-bdr);
      padding: calc(#{x(1.5)} + 2px) x(1.5);
      margin: 0;
    }

    body form & > .option__description {
      display: flex;
      align-items: center;
      min-width: x(4);
    }

    body form & > .option__description::before {
      margin: 0 x(1);
    }

    body form & .option--checkbox .option__description::before {
      margin-top: -2px;
    }

    // Confirm tag
    .tags {
      flex: 1 1 auto;
    }

    .tags .tag {
      margin-bottom: 0;
    }
  }

  .app &.option--max_width {
    flex: 1 1 auto;
  }

  body &--confirm:not(.option--check_field) > .option__description::before,
  body &--confirm_field:not(.option--check_field) > .option__description::before,
  body &--confirm_description > .option__description::before {
    border-radius: 100%;
  }

  body &--confirm:not(.option--check_field) > input:checked + .option__description::before,
  body &--confirm_field:not(.option--check_field) > input:checked + .option__description::before {
    background-color: var(--green);
    border-color: var(--green);
  }

  body &--confirm:not(.option--check_field) > input:disabled:checked + .option__description::before,
  body &--confirm_field:not(.option--check_field) > input:disabled:checked + .option__description::before {
    background-color: var(--green-light);
    border-color: var(--green-light);
  }

  // Disable container of checkbox when field is confirmed
  body &--confirm_field:not(.option--check_field) > input:checked + .option__description .option--checkbox {
    background-color: var(--bg-disabled);
  }

  // Minimal width
  &.option--min_width {
    margin-right: 0;
  }
}

// Select

select {
  padding: 0 x(1);
  height: var(--touch-target);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: var(--url-svg-drop_down);
  background-position: right x(1) center;
  background-repeat: no-repeat;
  padding-right: calc(var(--icon-1-5) + #{x(2)});
  cursor: pointer;
  line-height: var(--line-height);
  max-width: var(--width-column);
  color: var(--fg); // ### Should selects look like buttons or not?
  text-align: left;

  &.is-primary {
    background-image: var(--url-svg-drop_down--white);
  }

  &:focus:not(.is-tertiary),
  &.is-primary:focus {
    @extend %focus;
  }
}

// Multiselect

.multiselect {
  margin-bottom: x(2);
  min-width: 200px;

  .td--actions .btn-toolbar > &,
  table & {
    margin-bottom: 0;
  }

  input {
    min-height: 0;
  }

  .multiselect__control {
    @extend %border-radius;

    min-height: var(--touch-target);
    padding: 0 x(1);

    ///? Add button style hover on label hover
    label:hover + &:not(.multiselect__control--is-focused) {
      @extend %distance-medium;

      z-index: 1;
    }
  }

  .multiselect__value-container {
    padding: 0;
    text-align: left;

    // Multiselect wrapper
    > div:last-child {
      margin: 0;
      padding: 0;
    }
  }

  .multiselect__single-value,
  .multiselect__placeholder {
    margin: 0;
    overflow: visible;
    color: var(--fg);
  }

  .multiselect__placeholder {
    color: var(--fld-fg-placeholder);
  }

  .multiselect__input {
    float: left;
    color: var(--fld-fg);
  }

  .app & .multiselect__control .multiselect__input input:focus,
  .app & .multiselect__control .multiselect__input input {
    box-shadow: none;
    margin: 0;
    border-radius: 0;
    min-height: var(--icon-1-5);
    width: 100%;
  }

  .multiselect__indicator-separator {
    display: none;
  }

  .multiselect__indicator {
    margin-right: x(-1);
  }

  // Widths

  &--min_width {
    min-width: auto;
    width: var(--width-field-min);
  }

  &--small {
    min-width: auto;
    width: var(--width-field-small);
  }

  &--large {
    min-width: auto;
    width: var(--width-field-large);
  }
}

// Validation states

.multiselect.is-error .multiselect__control {
  border-color: var(--bdr-error);
}

.multiselect.is-warning .multiselect__control {
  border-color: var(--bdr-warning);
}

.multiselect.is-success .multiselect__control {
  border-color: var(--bdr-success);
}

// Multiselect drop down menu

.multiselect .multiselect__menu {
  left: 0;
  right: 0;
  z-index: 10;
  width: auto !important;
  margin-top: x(-.5) !important;
  border: 1px solid var(--bdr);
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0 1px 1px rgba(var(--black-rgb), .10),
  0 2px 3px rgba(var(--black-rgb), .10) !important;
  background-color: var(--bg-white);

  .multiselect__menu-list {
    max-height: 220px;
    overflow: auto;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
  }

  .multiselect__option {
    background-color: var(--bg-white);
    cursor: pointer;
    line-height: var(--line-height);
    white-space: normal;

    &--is-focused {
      background-color: var(--bg-nav-hover);
      color: var(--fg);
    }

    &--is-selected {
      background-color: var(--bg-nav-selected);
      color: var(--fg);
      cursor: default;
    }

    &:last-child {
      border-bottom-right-radius: var(--border-radius);
      border-bottom-left-radius: var(--border-radius);
    }
  }

  .multiselect__menu-notice {
    background-color: var(--bg-white);
    color: var(--fg-lighter);
  }
}

// Multiselect inline tags

.multiselect .multiselect__multi-value {
  @extend .tag;
  @extend %font-size-small;

  display: flex;
  padding: 2px 3px;
  height: var(--height-tag);
  line-height: 14px;
  margin: x(.25) x(.5) x(.25) 0;

  .multiselect__multi-value__label {
    font-size: inherit;
    color: var(--fg);
    border-radius: 0;
    padding: 0;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .multiselect__multi-value__remove {
    border-radius: 0 1px 1px 0;
    flex: 0 0;
    margin: x(-.5) calc(#{x(-.5)} - 1px) x(-.5) x(.5);
    padding: x(.5);

    &:hover,
    &:focus {
      background-color: var(--bg);

      path {
        stroke: var(--red);
      }
    }
  }

  // Position input better if there are multi-values
  & + div > .multiselect__input {
    margin: x(.25) x(.5) x(.25) 0;
  }
}

/// Needed?
.field.field--no_label .multiselect .multiselect__multi-value {
  margin: 0;
}

// Multiselect group heading

.multiselect .multiselect__group-heading {
  @extend %font-size-small;

  padding-top: x(.5);
  padding-bottom: x(.5);
}

// Text

.text {

  &--min_width {
    width: 35%;
    min-width: var(--width-field-min);

    .option--confirmation & {
      min-width: 0;
      width: 65px;
    }
  }

  &--max_width {
    width: 100%;

    .btn-toolbar & {
      flex: 1 0;
    }
  }

  &--zip {
    text-transform: uppercase;
  }
}

// Text cover (used mainly for Search)

.text--cover {
  width: 100%;
  margin-bottom: 0;
  background-repeat: no-repeat;
  border-color: var(--bdr-white);
  border-radius: 0;
  box-shadow: none;
  border: none;
  transition: box-shadow ease .2s,
  background-position .2s ease,
  padding-left .3s ease;

  .column__head--search & {
    background-image: var(--url-svg-search);
    background-position: left calc(#{x(-2)} - var(--icon-1-5)) center;
    padding-left: x(2);
    margin-top: x(-1);
    margin-bottom: x(-1);
    min-height: var(--touch-target);
  }

  &:hover,
  &:focus {
    @extend %distance-medium;
  }

  &:focus {
    @extend %focus-inline;
  }
}

// Search

// .text[type="search"] {
//   border-radius: calc(var(--touch-target) / 2);
//   padding: 0 x(1.5);
// }

// Overlay options
.btn-toolbar__input {
  position: absolute;
  top: 0;
  right: x(1);
  bottom: 0;
  display: flex;
  align-items: center;
  z-index: 2;

  .app .btn-toolbar > & {
    margin-bottom: 0;
  }

  > span {
    margin-left: x(.5);
  }

  .clear_button,
  .search_button {
    @extend %focusable;
  }

  .app & .icon {
    cursor: pointer;

    path,
    polyline {
      @extend %animate-stroke;

      stroke: var(--fg-lighterer);
    }

    &:hover,
    &:focus {
      path,
      polyline {
        stroke: var(--fg-light);
      }
    }
  }
}

// Textarea

.textarea {
  display: block; // Avoid bottom margin in some browsers
  padding-top: x(1);
  padding-bottom: x(1);
  min-height: 6em;

  &--max_width {
    width: 100%;
  }

  &--tall {
    min-height: 12em;
  }

  &--short {
    min-height: 4em;
  }
}

// Static Field (static_field)

.static_field {
  @extend %field;

  box-shadow: none;
  line-height: var(--height-field);
  margin-bottom: x(1);
  border: none;
  color: var(--fg-light);
  background-color: var(--bg-light);
}

// Input file (input[type=file])

input[type=file]:focus {
  outline: none;
}

// Input range (input[type=range])

input[type=range] {
  height: var(--icon-1-5);
  -webkit-appearance: none;
  margin: x(1) 0;
  width: 100%;
  cursor: pointer;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  @extend %border-radius-large;
  @extend %animate-colors;

  width: 100%;
  height: 2px;
  cursor: pointer;
  transition: 0.2s;
  background: var(--bg-darkerer);
}

.player.is-playing input[type=range]::-webkit-slider-runnable-track {
  background-color: var(--fg-link);
}

.app .player input[type=range]:hover::-webkit-slider-runnable-track,
.app .player input[type=range]:hover::-webkit-slider-runnable-track {
  background-color: var(--bg-assist);
}

input[type=range]::-webkit-slider-thumb {
  @extend %distance-small;

  -webkit-appearance: none;
  cursor: pointer;
  height: var(--icon);
  width: var(--icon);
  margin-top: calc(calc(calc(var(--icon) * -1) / 2) + 1px);
  border-radius: 50%;
  background: var(--bg-white);
  box-shadow: 0 0 0 2px var(--fg-link),
  0 1px 1px 2px rgba(var(--black-rgb), .10),
  0 1px 3px 2px rgba(var(--black-rgb), .10);
  transition: box-shadow .2s ease,
  background-color .2s ease;
}

input[type=range]:focus::-webkit-slider-thumb,
input[type=range]:hover::-webkit-slider-thumb {
  @extend %distance-medium;

  box-shadow: 0 0 0 2px var(--fg-assist),
  0 1px 2px 2px rgba(var(--black-rgb), .10),
  0 1px 4px 2px rgba(var(--black-rgb), .10),
  0 2px 8px 2px rgba(var(--black-rgb), .10);
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  transition: 0.2s;
  box-shadow: 0px 0px 0px (--black);
  background: #5077A7;
  border-radius: 2px;
  border: 0px solid (--black);
}

input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 0px var(--black);
  border: 0px solid var(--blue);
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: var(--blue);;
  cursor: pointer;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  transition: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: var(--blue);;
  border: 0px solid var(--black);
  border-radius: 4px;
  box-shadow: 0px 0px 0px var(--black);
}

input[type=range]::-ms-fill-upper {
  background: var(--blue);;
  border: 0px solid var(--black);
  border-radius: 4px;
  box-shadow: 0px 0px 0px var(--black);
}

input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px var(--black);
  border: 0px solid var(--blue);
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: var(--blue);;
  cursor: pointer;
}

input[type=range]:focus::-ms-fill-lower {
  background: var(--blue);
}

input[type=range]:focus::-ms-fill-upper {
  background: var(--blue);;
}

// Help text (help_text)

.help_text {
  @extend %font-size-small;

  line-height: var(--line-height);
  color: var(--fg-lighter);

  &--old {
    text-decoration: line-through;;
  }
}

// radio button looks like checkbox
.radio-input-wrap {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio-input-wrap input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: var(--white);
  border: 1px solid var(--grey-light);
}

.radio-input-wrap:hover input + .checkmark {
  background-color: (--white);
}

.radio-input-wrap input:checked + .checkmark {
  background-color: var(--blue);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.radio-input-wrap input:checked + .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.radio-input-wrap .checkmark:after {
  left: 6px;
  top: 2px;
  width: 6px;
  height: 11px;
  border: solid var(--white);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.toastifyBody {
  white-space: pre-wrap;
  line-height: 18px;
}

.toastifyOverlay {
  width: 100%;
  height: 100%;
  background-color: rgba(var(--black-rgb), 0.85);
  padding: 0;
  top: 0;
  bottom: 0;
}

.toastifyContentWithoutOverlay {
  width: 50%;
  position: fixed;
  align-items: center;
}

.toastifyContent {
  position: fixed;
  left: 25%;
  right: 25%;
  top: 2%;
  margin: 0;
  align-items: center;
}
