.toggle-button {
  border: none;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 2px 2px;
  cursor: pointer;
  transition: background-color 0.2s;
}
//FYI: Button Toggle colour is switched off
.toggle-button.toggled {
  //background-color: #EDF4F5;
}