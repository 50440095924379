/*
  Theme_dark.scss
  Dark theme overrides
*/

:root[data-theme="dark"] {
  @extend :root;

  %distance-medium-dark {
    box-shadow: 0 1px 2px rgba(var(--grey-abyss-rgb), .4),
    0 1px 4px rgba(var(--grey-abyss-rgb), .6),
    0 2px 8px rgba(var(--grey-abyss-rgb), .8);
  }

  %animate-distance {
    transition: background-color .2s ease,
    color .2s ease,
    stroke .2s ease,
    border-color .2s ease,
    outline .2s ease;
  }

  ::selection {
    color: var(--white);
  }

  ::-moz-selection {
    color: var(--white);
  }

  input {
    caret-color: var(--white);
  }

  // Show global loading indicator below in dark mode for better contrast
  .app.is-loading .panel__head::before {
    top: auto;
    bottom: -1px;
  }

  // Back button

  .link--nav:hover,
  .link--nav:focus {
    background-color: var(--bg-light);
  }

  .is-locked input[type=checkbox]:not(:focus):not(:checked) + .option__description::before {
    border: none;
  }

  input[type=checkbox]:hover:not(:disabled):not(:focus):not(:checked) + .option__description::before,
  input[type=radio]:hover:not(:disabled):not(:focus):not(:checked) + .option__description::before {
    background-color: var(--fld-bg-hover);
  }

  // This adds a bg to inline labels for textareas so you can scroll
  #app table:not(.is-locked) .field--textarea label:first-child,
  #app div:not(.is-locked) .field--textarea label:first-child {
    @extend %animate-distance;

    background: var(--black);
  }

  #app table:not(.is-locked) .field--textarea:hover label:first-child {
    background-color: var(--bg-nav-hover);
  }

  .file_preview:not(.file_preview--overlay):hover,
  .file_preview:not(.file_preview--overlay):focus,
  .message,
  button.is-primary:not(:focus) {
    box-shadow: none !important;
  }

  .card,
  .item,
  .column,
  .column:hover .column__head {
    box-shadow: none !important;
  }

  // Link
  .link--nav .icon svg * {
    stroke: var(--grey-light);
  }

  // Icon mods

  .icon:not(.is-static) *[fill="#FFF"] {
    fill: none;
  }

  // Special mod for order icon to scale dots
  .icon__group-order--color g g > circle {
    stroke: var(--bg);
  }

  .icon[class*=" icon__group"] g g,
  .icon[class*=" icon__process"] g g,
  .icon[class*=" icon__document"] g g {
    &[stroke],
    & *[stroke] {
      stroke: var(--icn-stroke);
    }

    &[fill],
    & *[fill] {
      fill: var(--icn-fill);
    }
  }

  /// Don't set a non-color icon to color in the future
  .tag--order path {
    stroke: var(--icn-stroke);
  }

  .icon__document-certificate polygon[fill="#FFF"],
  .icon__order.is-color circle,
  .icon__order-transport > g > path,
  .tag--order circle,
  .icon *[fill="#80500F"],
  .icon *[fill="#3C3566"] {
    fill: var(--icn-fill);
  }

  // All tags should be white in dark mode
  .app .tag {
    color: var(--fg);
  }

  .column,
  .navbar.panel__head {
    border-color: var(--bdr-dark);
  }

  .section--detail > .section__head {
    border-color: var(--bdr);
  }

  .panel--account .section__head,
  .panel--settings .section__head {
    background: var(--grey-darkest);
  }

  .section__nav__item {
    background-color: var(--grey-darkest);
  }

  // Hide card bg if order is shown in case
  .card .section__content--order_specification {
    background: none;
  }

  // Dropzone

  .dropzone__area {
    border-color: var(--bdr-dark);
  }

  // fnxtable

  .fnxtable--flat tr {
    border-color: var(--bdr-dark);
  }

  .fnxtable--flat thead .td,
  .fnxtable--flat thead .th {
    color: var(--fg-lighter);
  }

  // File preview

  .section__content--case_documents .file_preview {
    background-color: var(--bg-light);
  }

  div.file-preview:not(.file_preview--overlay) .file_preview__page {
    @extend %distance-medium-dark;
  }

  // Placeholder

  svg.icon__placeholder circle {
    fill: var(--fg);
  }

  .placeholders {

    .button.is-secondary {
      border-color: var(--bdr-dark);
    }
  }

  // Shortcut hint border
  .shortcut_icon {
    border-color: var(--bdr-white);
  }

  // Message

  .message {
    background-color: var(--grey-darker);
  }

  // Todo, email subscription
  /// refactor this

  #app .todo,
  #app .email_subscription {
    border-color: var(--bdr);
  }

  .email_subscription:not(.email_subscription--edit):not(.email_subscription--add) .email_subscription__head,
  .email_subscription:not(.email_subscription--edit):not(.email_subscription--add) .email_subscription__head,
  .todo:not(.todo--edit):not(.todo--add) .todo__head,
  .todo:not(.todo--edit):not(.todo--add) .todo__head {
    &:hover,
    &:focus {
      background-color: var(--bg-nav-hover);
    }
  }

  // Memory

  .memory.has-action:not(:hover) {
    background-color: var(--bg-light);
  }

  // ### temp
  .table-striped .btn-toolbar > * {
    box-shadow: none;
  }

  .is-placeholder, .item__meta--deceased .description, .column--single .item__meta--title .description, .item__title .description {
    color: var(--turqoise);
  }

  .column--detail .is-placeholder.column--detail .section__title .description, .item--order .item__meta--title .description, .section__content--edit_guest form h3.section__title.is-placeholder, .section__head .section__title span.description.is-placeholder, .section__content--contacts .card__title span.description.is-placeholder {
    color: var(--fg);
  }
}
