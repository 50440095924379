/*
  Theme_dark.scss
  Dark theme
  // commented lines indicate no change
*/

:root[data-theme="dark"] {

  // Essentials

  --accent: var(--turqoise);
  --accent-dark: var(--turqoise-light);

  // Backgrounds (bg)

  --bg: var(--grey-darkerer);
  --bg-light: var(--grey-darkest);
  --bg-lighter: var(--grey-abyss);
  --bg-dark: var(--grey-darker);
  --bg-darker: var(--grey-dark);
  --bg-darkerer: var(--grey);
  --bg-darkest: var(--grey-light);

  --bg-white: var(--grey-darkerer);
  --bg-black: var(--white);

  --bg-disabled: var(--grey-darker);
  --bg-success: #303536;

  // Nav states
  --bg-nav: var(--grey-darkerer);
  --bg-nav-selected: var(--grey-darker);
  --bg-nav-hover: var(--grey-darker);
  --bg-nav-pressed: var(--grey-darkest);

  // Table
  --bg-row-hover: var(--bg-light);
  --bg-row-button-hover: var(--bg-nav-hover);
  --bg-row-selected: var(--bg-nav-selected);

  // Statuses
  --bg-message: var(--white);
  --bg-message-success: var(--label-green);
  --bg-message-error: var(--label-red);

  // Drag target status
  --bg-target: var(--green);
  --bg-target-hover: var(--accent);

  // Accessibility
  //--bg-assist: var(--turqoise);

  // Nav
  --bg-system: var(--grey-darkest);

  // Card
  --bg-card: #303536;
  --bg-card-hover: var(--grey-darker);

  --bg-card-new: #303536;
  --bg-card-new-hover: var(--grey-darker);

  --bg-card-live: #303536;
  --bg-card-live-hover: var(--grey-darker);

  // Item
  --bg-item: var(--grey-darkerer);
  --bg-item-selected: var(--grey-darker);
  --bg-item-hover: var(--grey-darkest);
  --bg-item-pressed: var(--grey-abyss);

  --bg-item-new: var(--grey-darkerer);
  --bg-item-new-selected: var(--grey-darker);
  --bg-item-new-hover: var(--grey-darkest);
  --bg-item-new-pressed: var(--grey-abyss);

  --bg-item-live: var(--grey-darkerer);
  --bg-item-live-selected: var(--grey-darker);
  --bg-item-live-hover: var(--grey-darkest);
  --bg-item-live-pressed: var(--grey-abyss);

  // Count
  --bg-count: var(--bg);
  --bg-count-new: var(--accent);
  --bg-count-due: var(--bg);
  --bg-count-overdue: var(--bdr-error);

  // Foregrounds (fg, used to be called 'color')

  --fg: var(--grey-lighterer);
  --fg-light: var(--grey-lighter);
  --fg-lighter: var(--grey-light);
  --fg-lighterer: var(--grey);
  --fg-dark: var(--grey-lightest);

  --fg-white: var(--black);
  --fg-black: var(--white);
  --fg-green: var(--green-light);

  --fg-new: var(--turqoise); ///
  --fg-live: var(--green);

  // Links
  --fg-link: var(--blue-light);
  --fg-link-hover: var(--blue-lighter);
  --fg-link-pressed: var(--blue);
  --fg-link-disabled: rgba(var(--blue-light-rgb), .65);

  // Statuses
  --fg-message: var(--black);
  --fg-message-success: var(--white);
  --fg-message-error: var(--white);

  // Accessibility
  //--fg-assist: var(--turqoise);

  // Count
  --fg-count: var(--fg);
  --fg-count-new: var(--fg-white);
  --fg-count-due: var(--fg);
  --fg-count-overdue: var(--fg-white);

  // Borders (bdr)

  --bdr: var(--grey-darker);
  --bdr-light: var(--grey-darkerer);
  --bdr-dark: var(--grey-dark);
  --bdr-white: var(--white);
  --bdr-black: var(--black);
  --bdr-accent: var(--accent-dark);

  // Accessibility
  --bdr-assist: var(--turqoise);

  // Statuses
  --bdr-error: var(--red-light);
  --bdr-warning: var(--yellow-light);
  --bdr-success: var(--green-light);

  // Count
  --bdr-count: var(--fg);
  --bdr-count-new: transparent;
  --bdr-count-due: var(--bdr-error);
  --bdr-count-overdue: var(--bdr-error);

  // Icons (icn)

  --icn-fill-destructive: var(--red);
  --icn-stroke: var(--grey-lighter);
  --icn-stroke-turqoise: var(--grey-lighter);
  --icn-stroke-orange: var(--grey-lighter);
  --icn-stroke-green: var(--grey-lighter);

  --icn-fill: var(--grey-lighter);
  --icn-fill-orange: var(--grey-lighter);

  // Fields (fld)

  --fld-bg: var(--black);
  --fld-bg-hover: var(--grey-darker);
  --fld-bg-disabled: var(--grey-darkerer);

  --fld-fg: var(--fg);
  --fld-fg-hover: var(--fg);
  --fld-fg-placeholder: var(--fg-light);
  --fld-fg-disabled: var(--fg-lighter);

  --fld-bdr: var(--grey-dark);
  --fld-bdr-hover: var(--grey);
  --fld-bdr-disabled: var(--grey-dark);
  --fld-bdr-error: var(--bdr-error);
  --fld-bdr-warning: var(--bdr-warning);
  --fld-bdr-success: var(--bdr-success);

  // Option (opt)

  --opt-bg: var(--fld-bg);
  --opt-bg-hover: var(--fld-bg);
  --opt-bg-disabled: var(--fld-bg-disabled);
  --opt-bg-checked: var(--blue);
  --opt-bg-checked-disabled: var(--blue-dark);

  --opt-fg: var(--fld-fg);
  --opt-fg-checked: var(--white);

  --opt-bdr: var(--fld-bdr);
  --opt-bdr-hover: var(--fld-bdr-hover);
  --opt-bdr-disabled: var(--fld-bdr-disabled);
  --opt-bdr-checked: var(--blue);
  --opt-bdr-checked-hover: var(--blue);
  --opt-bdr-checked-disabled: var(--blue-dark);

  // Buttons (btn)
  /// colors...

  // Primary
  --btn-primary: var(--blue);
  --btn-primary-hover: #5e84b2;
  --btn-primary-pressed: #3f5e85;
  --btn-primary-disabled: var(--grey-dark);

  --btn-primary-fg: var(--white);
  --btn-primary-fg-pressed: var(--blue-lightest);
  --btn-primary-fg-disabled: var(--grey-lightest);

  --btn-primary-bdr: transparent;

  // Secondary
  --btn-secondary: var(--black);
  --btn-secondary-hover: var(--grey-darker);
  --btn-secondary-pressed: var(--grey-darkest);
  --btn-secondary-disabled: var(--grey-darkerer);

  --btn-secondary-fg: var(--blue-lighter);
  --btn-secondary-fg-pressed: var(--blue);
  --btn-secondary-fg-disabled: var(--grey);

  --btn-secondary-bdr: var(--blue);
  --btn-secondary-bdr-disabled: var(--bdr-dark);

  // Tertiary
  --btn-tertiary: transparent;
  --btn-tertiary-hover: var(--grey-darker);

  --btn-tertiary-fg: var(--fg-link);
  --btn-tertiary-fg-hover: var(--fg-link-hover);
  --btn-tertiary-fg-pressed: var(--fg-link-pressed);
  --btn-tertiary-fg-disabled: var(--fg-link-disabled);
  --btn-tertiary-fg-destructive: var(--grey);
  --btn-tertiary-fg-destructive-hover: var(--red);
  --btn-tertiary-fg-destructive-pressed: #b93a34;
  --btn-tertiary-fg-destructive-disabled: rgba(var(--red-rgb), .65);

  --btn-tertiary-bdr: transparent;
  --btn-tertiary-bdr-hover: var(--blue);

  // Destructive
  --btn-destructive: var(--red);
  --btn-destructive-hover: #d46a66;
  --btn-destructive-pressed: #b93a34;
  --btn-destructive-disabled: var(--grey);

  --btn-destructive-fg: var(--white);
  --btn-destructive-fg-pressed: var(--red-lightest);
  --btn-destructive-fg-disabled: rgba(var(--red-rgb), .65);

  // Labels (lbl)

  --lbl-grey: var(--label-grey);
  --lbl-grey_light: var(--label-grey_light);
  --lbl-red: var(--label-red);
  --lbl-orange: var(--label-orange);
  --lbl-yellow: var(--label-yellow);
  --lbl-green: var(--label-green);
  --lbl-green_mint: var(--label-green_mint);
  --lbl-turqoise: var(--label-turqoise);
  --lbl-blue: var(--label-blue);
  --lbl-purple: var(--label-purple);
  --lbl-pink: var(--label-pink);
  --lbl-pink_hot: var(--label-pink_hot);

  // Tags

  --tag-active: var(--grey-darkerer);
  --tag-active-bdr: var(--bdr-accent);
  --tag-warned: var(--grey-darkerer);
  --tag-warned-bdr: var(--bdr-warning);

  // Banners

  --bnr-warning: var(--grey-darker);

  // Scrollbars

  --bg-scrollbar: var(--bg);
  --bg-scrollbar-hover: #303536;
  --bg-scrollbar-thumb: var(--bg-darker);
  --bg-scrollbar-thumb-hover: var(--bg-darkerer);
  // --bg-scrollbar-thumb-active: var(--bg-assist);

}
