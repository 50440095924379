.align-flex-end {
  display: flex;
  justify-content: flex-end;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.wordBreak {
  word-break: break-word;
  white-space: break-spaces;
}

.date-picker {
  margin-bottom: 18px;
  height: 50px;
}

.button-order-tab {
  margin-bottom: 10px;
  margin-left: 10px;
}

.required-field::after {
  content: "*";
  color: red;
  font-size: 20px;
  font-weight: 300;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: x(2);

  .col-1 {
    flex: 1 0 50%;
    width: 50%;
  }
}