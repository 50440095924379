$red: #CE3446;
$white: #ffffff;
$gray-100: #f5f5f5;
$gray-200: #ebebeb;
$gray-300: #d8d8d8;
$gray-400: #d5d5d5;
$gray-500: #b1b1b1;
$gray-600: #909090;
$gray-700: #696969;
$gray-800: #333333;
$gray-900: #1f1f1f;
$black: #000000;