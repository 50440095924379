
.includesTime .react-datepicker__navigation--next {
  margin-right: 395px;
}

// Time
.includesTime .react-datepicker__time-container {
  width: 465px !important;
}

.includesTime .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul {
  display: flex;
  flex-wrap: wrap;
  width: 100% !important;
}

.summary {
  width: 60%;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 50px;
}

.sfTable {
  width: 100%;
  margin: 0;
}