/*
  _vars.scss
  Global variables
*/

:root {

  --x: 8px; // base distance
  // 2px  = x(.25)  = N/A
  // 4px  = x(.5)   = --margin-min
  // 8px  = x(1)    = --margin-base
  // 12px = x(1.5)  = --margin-small
  // 16px = x(2)    = --margin-default
  // 24px = x(3)    = --margin-large
  // 32px = x(4)    = --margin-larger
  // 40px = x(5)    = --margin-largerer
  // 48px = x(6)    = --margin-largest
  // 64px = x(8)    = --margin-huge

  --touch-target: 40px;
  --border-radius: 2px;
  --border-radius-large: 4px;

  // Icons

  --icon: 16px;
  --icon-1-5: 24px;
  --icon-2: 32px;
  --icon-2-5: 40px;
  --icon-3: 48px;

  // Widths

  --width-panel: 320px;
  --width-column: 360px;
  --width-column-sidebar: 320px;
  --width-inner: 1400px;
  --width-min: 1024px;

  // Field widths
  --width-field-min: 128px;
  --width-field-small: 160px;
  --width-field-large: 320px;
  --width-field-xlarge: 480px;
  --width-field-xxlarge: 640px;
  --width-field-date: 116px;
  --width-field-time: 65px;

  // Heights

  --height-panel_head: 56px;
  --height-column_head: 40px;
  --height-tag: 20px;
  --height-field: 48px;

  // Stroke width

  --width-stroke: 1px;
  --width-stroke-1-5: .75px;
  --width-stroke-2: .5px;
  --width-stroke-2-5: .415px;
  --width-stroke-3: .333px;

  // Icons

  --url-svg-search: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16"%3E%3Cg fill="none" fill-rule="evenodd"%3E%3Ccircle cx="7" cy="7" r="3.5" stroke="%23C0C3C3"/%3E%3Cpath stroke="%23C0C3C3" stroke-linecap="round" stroke-linejoin="round" d="M9.5,9.5 L12.5,12.5"/%3E%3C/g%3E%3C/svg%3E%0A');
  --url-svg-drop_down: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16"%3E%3Cpath fill="%23555" d="M11.3535534,9.64644661 C11.6685358,9.96142904 11.4454524,10.5 11,10.5 L5,10.5 C4.55454757,10.5 4.33146418,9.96142904 4.64644661,9.64644661 L7.64644661,6.64644661 C7.84170876,6.45118446 8.15829124,6.45118446 8.35355339,6.64644661 L11.3535534,9.64644661 Z" transform="rotate(180 8 8.5)"/%3E%3C/svg%3E%0A');
  --url-svg-drop_down--white: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16"%3E%3Cpath fill="%23FFF" d="M11.3535534,9.64644661 C11.6685358,9.96142904 11.4454524,10.5 11,10.5 L5,10.5 C4.55454757,10.5 4.33146418,9.96142904 4.64644661,9.64644661 L7.64644661,6.64644661 C7.84170876,6.45118446 8.15829124,6.45118446 8.35355339,6.64644661 L11.3535534,9.64644661 Z" transform="rotate(180 8 8.5)"/%3E%3C/svg%3E%0A');
  --url-svg-check: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16"%3E%3Cpolyline fill="none" stroke="%23fff" stroke-linecap="round" stroke-linejoin="round" points="4 8.5 6.5 11 12.5 5"/%3E%3C/svg%3E%0A');
  --url-svg-success--filled: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"%3E%3Cpath fill="%23FFF" d="M8,13.5 C4.96243388,13.5 2.5,11.0375661 2.5,8 C2.5,4.96243388 4.96243388,2.5 8,2.5 C11.0375661,2.5 13.5,4.96243388 13.5,8 C13.5,11.0375661 11.0375661,13.5 8,13.5 Z M7.5,8.79289322 L6.35355339,7.64644661 C6.15829124,7.45118446 5.84170876,7.45118446 5.64644661,7.64644661 C5.45118446,7.84170876 5.45118446,8.15829124 5.64644661,8.35355339 L7.14644661,9.85355339 C7.34170876,10.0488155 7.65829124,10.0488155 7.85355339,9.85355339 L10.8535534,6.85355339 C11.0488155,6.65829124 11.0488155,6.34170876 10.8535534,6.14644661 C10.6582912,5.95118446 10.3417088,5.95118446 10.1464466,6.14644661 L7.5,8.79289322 Z"/%3E%3C/svg%3E%0A');
  --url-svg-warning--color: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"%3E%3Cg fill="none" fill-rule="evenodd"%3E%3Cpath fill="%23EBDE36" fill-rule="nonzero" d="M2.80384758,13 L1.93782217,13 C1.55292199,13 1.31235938,12.5833333 1.50480947,12.25 L7.5669873,1.75 C7.75943739,1.41666667 8.24056261,1.41666667 8.4330127,1.75 L14.4951905,12.25 C14.6876406,12.5833333 14.447078,13 14.0621778,13 L13.1961524,13 L2.80384758,13 Z"/%3E%3Cpath stroke="%23222" stroke-linecap="round" stroke-linejoin="round" d="M8,6.5 L8,10.5"/%3E%3C/g%3E%3C/svg%3E%0A');
  --url-svg-error--color: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"%3E%3Cg fill="none" fill-rule="evenodd"%3E%3Cpath fill="%23CE5752" fill-rule="nonzero" d="M13.5,5.89706961 L13.5,10.1029304 C13.5,10.2151374 13.455426,10.3227486 13.3760836,10.402091 L10.402091,13.3760836 C10.3227486,13.455426 10.2151374,13.5 10.1029304,13.5 L5.89706961,13.5 C5.78486263,13.5 5.67725136,13.455426 5.59790905,13.3760836 L2.62391636,10.402091 C2.54457405,10.3227486 2.5,10.2151374 2.5,10.1029304 L2.5,5.89706961 C2.5,5.78486263 2.54457405,5.67725136 2.62391636,5.59790905 L5.59790905,2.62391636 C5.67725136,2.54457405 5.78486263,2.5 5.89706961,2.5 L10.1029304,2.5 C10.2151374,2.5 10.3227486,2.54457405 10.402091,2.62391636 L13.3760836,5.59790905 C13.455426,5.67725136 13.5,5.78486263 13.5,5.89706961 Z"/%3E%3Cpath stroke="%23FFF" stroke-linecap="round" stroke-linejoin="round" d="M6 10L10 6M6 6L10 10"/%3E%3C/g%3E%3C/svg%3E%0A');
  --url-svg-error--filled: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"%3E%3Cpath fill="%23FFF" d="M7.29289322,8 L5.64644661,9.64644661 C5.45118446,9.84170876 5.45118446,10.1582912 5.64644661,10.3535534 C5.84170876,10.5488155 6.15829124,10.5488155 6.35355339,10.3535534 L8,8.70710678 L9.64644661,10.3535534 C9.84170876,10.5488155 10.1582912,10.5488155 10.3535534,10.3535534 C10.5488155,10.1582912 10.5488155,9.84170876 10.3535534,9.64644661 L8.70710678,8 L10.3535534,6.35355339 C10.5488155,6.15829124 10.5488155,5.84170876 10.3535534,5.64644661 C10.1582912,5.45118446 9.84170876,5.45118446 9.64644661,5.64644661 L8,7.29289322 L6.35355339,5.64644661 C6.15829124,5.45118446 5.84170876,5.45118446 5.64644661,5.64644661 C5.45118446,5.84170876 5.45118446,6.15829124 5.64644661,6.35355339 L7.29289322,8 Z M13.5,5.89706961 L13.5,10.1029304 C13.5,10.2151374 13.455426,10.3227486 13.3760836,10.402091 L10.402091,13.3760836 C10.3227486,13.455426 10.2151374,13.5 10.1029304,13.5 L5.89706961,13.5 C5.78486263,13.5 5.67725136,13.455426 5.59790905,13.3760836 L2.62391636,10.402091 C2.54457405,10.3227486 2.5,10.2151374 2.5,10.1029304 L2.5,5.89706961 C2.5,5.78486263 2.54457405,5.67725136 2.62391636,5.59790905 L5.59790905,2.62391636 C5.67725136,2.54457405 5.78486263,2.5 5.89706961,2.5 L10.1029304,2.5 C10.2151374,2.5 10.3227486,2.54457405 10.402091,2.62391636 L13.3760836,5.59790905 C13.455426,5.67725136 13.5,5.78486263 13.5,5.89706961 Z"/%3E%3C/svg%3E%0A');
  --url-svg-external: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"%3E%3Cg fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"%3E%3Cpath stroke="%23222" d="M7 5L4 5C3.44771525 5 3 5.44771525 3 6L3 12C3 12.5522847 3.44771525 13 4 13L10 13C10.5522847 13 11 12.5522847 11 12L11 9M7 9L13 3"/%3E%3Cpolyline stroke="%23222" points="9 3 13 3 13 7"/%3E%3C/g%3E%3C/svg%3E%0A');
  --url-svg-note: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"%3E%3Cg fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"%3E%3Cpath stroke="%23222" d="M4 9L4 7C4 5.8954305 4.8954305 5 6 5L6 5M9 9L9 7C9 5.8954305 9.8954305 5 11 5L11 5"/%3E%3Ccircle cx="5.5" cy="9" r="1.5" stroke="%23222"/%3E%3Ccircle cx="10.5" cy="9" r="1.5" stroke="%23222"/%3E%3C/g%3E%3C/svg%3E%0A');

}

.tdFixedWidth {
  width: 500px;
}
