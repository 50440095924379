/*
  Colors.scss
  Global color variables & defaults
*/

// Colors

:root {

  // Grey
  --grey-lightest: #F3F5F5;
  --grey-lighterer: #EEF0F0;
  --grey-lighter: #E0E2E2;
  --grey-light: #C0C3C3;
  --grey: #888F8F;
  --grey-dark: #626969;
  --grey-darker: #373D3E;
  --grey-darkerer: #242829;
  --grey-darkest: #1F2122;
  --grey-abyss: #161818;
  --grey-rgb: 136, 143, 143;
  --grey-abyss-rgb: 22, 24, 24;

  // Red
  --red-lightest: #FFF3F2;
  --red-lighter: #F7DDDC;
  --red-light: #F7AEAB;
  --red: #CE5752;
  --red-dark: #802B28;
  --red-darkest: #361211;
  --red-rgb: 206, 87, 82;

  // Orange
  --orange-lightest: #F9F3EC;
  --orange-lighter: #F9E4C9;
  --orange-light: #F5D1A0;
  --orange: #F4A944;
  --orange-dark: #80500F;
  --orange-darkest: #382205;

  // Yellow
  --yellow-lightest: #FFFEF3;
  --yellow-lighter: #F8F7DE;
  --yellow-light: #F1ECA6;
  --yellow: #EBDE36;
  --yellow-900: #6E6711;

  // Green
  --green-lightest: #F1F8ED;
  --green-lighter: #E0EDD8;
  --green-light: #B6D7A1;
  --green: #71A750;
  --green-dark: #507439;
  --green-darkest: #344C25;
  --green-rgb: 113, 167, 80;

  // Green mint
  --green_mint-lightest: #EEF6F3;
  --green_mint-lighter: #CEE3DB;
  --green_mint-light: #A2DCC7;
  --green_mint: #4EBF96;
  --green_mint-dark: #2C5948;
  --green_mint-darkest: #1D2824;

  // Turqoise
  --turqoise-lightest: #EFF5F6;
  --turqoise-lighterer: #DEEBED;
  --turqoise-lighter: #CCE0E3;
  --turqoise-light: #9BD2DA;
  --turqoise: #53B1BF;
  --turqoise-dark: #3E7B84;
  --turqoise-darker: #20545C;
  --turqoise-darkest: #14373C;
  --turqoise-rgb: 83, 177, 191;

  // Blue
  --blue-lightest: #F3F7FC;
  --blue-lighter: #D3E1F3;
  --blue-light: #A8C2E1;
  --blue: #5077A7;
  --blue-dark: #273E5B;
  --blue-darkest: #162230;
  --blue-rgb: 80, 119, 167;
  --blue-light-rgb: 168, 194, 225;

  // Purple
  --purple-lightest: #F4F2FF;
  --purple-lighter: #DDD9F6;
  --purple-light: #BBB5E2;
  --purple: #665CA8;
  --purple-dark: #3C3566;
  --purple-darkest: #201C36;

  // Pink
  --pink-lightest: #F9EFF3;
  --pink-lighter: #F6DFE8;
  --pink-light: #EABACD;
  --pink: #C44D7C;
  --pink-dark: #662A42;
  --pink-darkest: #2D1B22;

  // Essential colors
  --white: rgba(255, 255, 255, 1);
  --white-rgb: 255, 255, 255;
  --black: var(--grey-darkest);
  --black-rgb: 31, 33, 34;

  // Label colors
  --label-grey: var(--grey-dark);
  --label-grey_light: var(--grey-light);
  --label-red: #af322d;
  --label-orange: #F4A944;
  --label-yellow: #E9D000;
  --label-green: #3d7b17;
  --label-green_mint: #71e0b8;
  --label-turqoise: #177b8a;
  --label-blue: #3065a5;
  --label-purple: #665CA8;
  --label-pink: #c73f75;
  --label-pink_hot: #E0669B;

}
