/*
  Spinner
*/

@keyframes rotate {
  0% {
    transform: rotate(-45deg);
  }

  100% {
    transform: rotate(315deg);
  }
}

.spinner__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 1 auto;
  overflow: hidden;
}

.spinner {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: x(2) 0;
}

.spinner__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.spinner__animation {
  position: relative;
  display: inline-block;
  width: 64px;
  height: 64px;
  border: 2px solid var(--bdr-light);
  border-radius: 50%;
  transform: rotate(-45deg);
  animation: rotate 2s infinite linear;
  text-align: center;

  .file_preview:not(.file_preview--overlay) > .spinner__wrapper &,
  .spinner--grey & {
    border-color: var(--bdr);
  }

  .overlay &,
  .spinner--white & {
    border-color: var(--white);
  }

  .file_preview__page .spinner__wrapper & {
    border-color: var(--grey-lighter);
  }
}

.spinner__animation::before {
  content: '';
  display: inline-block;
  position: absolute;
  margin-top: -6px;
  margin-left: -6px;
  width: 12px;
  height: 12px;
  background: var(--bg-white);
  border-radius: 50%;
  border: 2px solid var(--accent);
  box-sizing: border-box;
  transition: all 5s ease;
  transition-delay: 4s;

  .overlay &,
  .spinner--white & {
    background-color: var(--accent);
    border-color: var(--white);
  }

  .file_preview:not(.file_preview--overlay) > .spinner__wrapper &,
  .spinner--grey & {
    background-color: var(--bg);
  }

  .spinner__content:hover & {
    border-color: var(--purple);
    margin-top: -360px;
    transform: scale(4);
  }

  .file_preview__page .spinner__wrapper & {
    border: 2px solid var(--accent);
    background: var(--white);
  }
}

.spinner__description {
  margin-bottom: 0;
  position: relative;

  .overlay &,
  .spinner--white & {
    color: var(--white); // Same in light and dark mode
  }

  .spinner__animation + & {
    margin-top: x(2);
  }

  &::after {
    content: 'wohoo! 3..2..1...';
    display: block;
    opacity: 0;
    transition: opacity .5s ease;
    transition-delay: 2s;
    position: absolute;
    right: 0;
    left: 0;
    text-align: center;
  }

  .spinner__content:hover &::after {
    opacity: 1;
  }

  .file_preview__page & {
    color: var(--grey);
  }
}

:root[data-theme="dark"] {
  #app div:not(.file_preview__page) .spinner__wrapper .spinner__animation,
  #app div:not(.file_preview__page) .spinner--grey .spinner__animation {
    border-color: var(--white);
  }

  #app.app .file_preview__page .spinner__wrapper .spinner__animation {
    border-color: var(--grey-lighter);
  }

  #app div:not(.file_preview__page) .spinner__wrapper .spinner__animation::before,
  #app div:not(.file_preview__page) .spinner--grey .spinner__animation::before {
    background-color: var(--accent);
    border-color: var(--white);
  }

  #app.app .file_preview__page .spinner__wrapper .spinner__animation::before {
    border: 2px solid var(--accent);
    background: var(--white);
  }

  #app .file_preview__page .spinner__description {
    color: var(--grey);
  }
}
