@import "styles/palette";

.fallback {
  display: block;
  border: 0.063rem solid $red;
  padding: 1rem;
  border-radius: 0.313rem;
  text-align: center;


  &__title {
    padding: 0;
    margin: 0;
    color: $red;
  }

  &__body {
    word-wrap: break-word;
    white-space: pre-wrap;
  }
}