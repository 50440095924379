/*
  Placeholders, placeholder
*/

.placeholders {
  position: relative;
  pointer-events: none;

  &--tall {
    min-height: 160px;
  }

  .drag__content__list > & {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: all; /// remove
  }

  > *:not(.spinner__wrapper) {
    @extend %animation-freeze;

    opacity: .5;
  }

  .spinner__wrapper {
    @extend %animation-reveal-spinner;

    opacity: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
  }

  input[type=checkbox] + .option__description::before,
  input[type=radio] + .option__description::before,
  input[type=text],
  textarea,
  button,
  .button,
  select,
  .multiselect,
  .btn-toolbar > * {
    box-shadow: none;
    opacity: .8;
  }

  .button,
  .link {
    color: var(--fg-light);
  }

  .button.is-destructive {
    opacity: .6;
  }
}

span.icon__placeholder {
  @extend %animation-flow;

  // Make flow animation cut around icon
  border-radius: 50%;
  opacity: .5;

  #app & svg.icon__placeholder circle {
    fill: var(--fg-lighterer);
  }
}

// Placeholder

.placeholder {
  @extend %border-radius;
  @extend %animation-flow;

  display: inline-block;
  max-width: 50%;
  width: 100px;
  background: var(--fg-lighterer);
  vertical-align: middle;
  align-self: center;
  opacity: .5;
  line-height: .75;

  &--min_width {
    width: 24px;
    max-width: none;
  }

  &--small {
    max-width: 25%;
    width: 25%;
  }

  &--medium {
    max-width: 50%;
    width: 50%;
  }

  &--large {
    max-width: 75%;
    width: 75%;
  }

  &--max_width {
    max-width: 100%;
    width: 100%;
  }
}

// Placeholder card

.placeholder_card {
  min-height: 80px;

  .column:not(.column--single) & {
    @extend %card;
  }

  .column--single & {
    background: var(--bg-white);
  }
}
