/*
  _animation.scss
  Animation extends, mixins, etc
*/

// Keyframes

// Slide and fade upwards
@keyframes slide-up {
  0% {
    top: x(1);
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

// Highligt recently selected element
// Needs to be removed after 1s
@keyframes was-active {
  50% {
    background-color: var(--turqoise-lighter);
  }
}

// Highlight new element
// Needs to be removed after 1s
@keyframes is-new {
  50% {
    background-color: var(--turqoise-lighter);
    transform: scale(1.02);
  }
}

// Body loading (running bar in nav)
@keyframes loading-bar {
  0% {
    left: 0%;
    right: 100%
  }

  20% {
    left: 0%;
    right: 60%;
  }

  50% {
    left: 30%;
    right: 30%;
  }

  80% {
    left: 60%;
    right: 0%;
  }

  100% {
    left: 100%;
    right: 0%;
  }
}

// Pulsate
@keyframes pulsate {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

// Drag hint
@keyframes drag-hint {
  0%, 50% {
    cursor: pointer;
  }

  51%, 100% {
    cursor: move;
  }
}

// Time out interaction, use with 3s
@keyframes time-out {
  0%, 98% {
    pointer-events: none;
    opacity: .5;
    cursor: not-allowed;
  }

  100% {
    pointer-events: all;
    opacity: 1;
    cursor: pointer;
  }
}

// Wiggle
@keyframes wiggle {
  0%, 12%, 100% {
    transform: rotate(0deg) translate(3px, 2.5px);
  }

  4% {
    transform: rotate(5deg) translate(3px, 2.5px);
  }

  6% {
    transform: rotate(-5deg) translate(3px, 2.5px);
  }

  8% {
    transform: rotate(2.5deg) translate(3px, 2.5px);
  }

  10% {
    transform: rotate(-2.5deg) translate(3px, 2.5px);
  }
}

// Wiggle notification pendulum
@keyframes wiggle-pendulum {
  0%, 10%, 100% {
    transform: matrix(1, 0, 0, -1, 0, 19);
  }

  2% {
    transform: matrix(1, 0, 0, -1, -2, 19);
  }

  4% {
    transform: matrix(1, 0, 0, -1, 2, 19);
  }

  6% {
    transform: matrix(1, 0, 0, -1, -1, 19);
  }

  8% {
    transform: matrix(1, 0, 0, -1, 1, 19);
  }
}

/// Has loaded
@keyframes loaded-pop {
  0% {
    background-color: var(--bg-assist);
  }

  100% {
    background-color: var(--bg-white);
  }
}

// Wave
@keyframes wave {
  0%, 50%, 100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-10deg);
  }
  75% {
    transform: rotate(10deg);
  }
}

// Placeholder flow
@keyframes flow {
  0% {
    transform: translate(-100%);
  }

  100% {
    transform: translate(150%);
  }
}

// Freeze placeholders
@keyframes freeze {
  0%, 75% {
    opacity: 1;
  }

  100% {
    opacity: .5;
  }
}


// Fade in after delay
@keyframes reveal {
  0%, 70% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

// Animations (CSS animation)

%animation-reveal {
  animation: reveal 1s ease 1;
  opacity: 1;
}

%animation-reveal-spinner {
  animation: reveal 4s ease 1;
  opacity: 1;
}

%animation-new {
  animation: is-new .5s 1 ease;
}

%animation-loading {

  &::before {
    content: "";
    display: block;
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;
    left: 0;
    height: 1px;
    background: var(--fg-assist);
    animation: loading-bar 2s linear infinite;
    opacity: 1;
  }
}

%animation-loading-bottom {
  @extend %animation-loading;

  &::before {
    top: auto;
    bottom: -1px;
  }
}

%animation-freeze {
  animation: freeze 4s linear 1;
}

%animation-drag-hint {
  animation: drag-hint 4s linear 1;
}

%animation-time-out {
  animation: time-out 3s linear 1;
  opacity: 1;
  pointer-events: all;
}

%animation-flow {
  position: relative;
  overflow: hidden;

  &::before {
    animation: flow 1s ease infinite;
    transform: translate(-100%);
    width: 100%;
    background: linear-gradient(90deg, transparent 0%, var(--fg-lighter) 75%, transparent 100%); // Should be fg since this represents fg elements
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }
}

%animation-pulsate {
  animation: pulsate 1s ease infinite;
}

%animation-active {
  animation: was-active .8s infinite ease;
}

%animation-loaded {
  animation: loaded-pop .5s ease-out 1;
}

// Animate (CSS transition)

%animate-opacity {
  transition: opacity .2s ease;
}

%animate-distance {
  transition: box-shadow .2s ease-out;
}

%animate-colors {
  transition: background-color .2s ease,
  color .2s ease,
  stroke .2s ease,
  border-color .2s ease,
  outline .2s ease;
}

%animate-stroke {
  transition: stroke .2s ease,
  stroke-width .2s ease;
}

%animate-fill {
  transition: fill .2s ease;
}

// Just to overide the animation provided by react loader component
// ### todo: remove when fixed in source
svg polygon {
  -webkit-animation: none !important;
  animation: none !important;
  stroke-dasharray: 0 !important;
}
